/* https://gwfh.mranftl.com/fonts/barlow?subsets=latin */
$font-path: "../fonts";
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: local('Barlow Regular'), local('Barlow-Regular'), url('#{$font-path}/barlow-v12-latin-regular.woff2') format('woff2'), url('#{$font-path}/barlow-v12-latin-regular.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src: local('Barlow Italic'), local('Barlow-Italic'), url('#{$font-path}/barlow-v12-latin-italic.woff2') format('woff2'), url('#{$font-path}/barlow-v12-latin-italic.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src: local('Barlow Light'), local('Barlow-Light'), url('#{$font-path}/barlow-v12-latin-300.woff2') format('woff2'), url('#{$font-path}/barlow-v12-latin-300.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 300;
  src: local('Barlow Light Italic'), local('Barlow-LightItalic'), url('#{$font-path}/barlow-v12-latin-300Italic.woff2') format('woff2'), url('#{$font-path}/barlow-v12-latin-300Italic.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: local('Barlow Medium'), local('Barlow-Medium'), url('#{$font-path}/barlow-v12-latin-500.woff2') format('woff2'), url('#{$font-path}/barlow-v12-latin-500.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 500;
  src: local('Barlow Medium Italic'), local('Barlow-MediumItalic'), url('#{$font-path}/barlow-v12-latin-500Italic.woff2') format('woff2'), url('#{$font-path}/barlow-v12-latin-500Italic.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: local('Barlow Medium'), local('Barlow-Bold'), url('#{$font-path}/barlow-v12-latin-700.woff2') format('woff2'), url('#{$font-path}/barlow-v12-latin-700.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 700;
  src: local('Barlow Medium Italic'), local('Barlow-BoldItalic'), url('#{$font-path}/barlow-v12-latin-700Italic.woff2') format('woff2'), url('#{$font-path}/barlow-v12-latin-700Italic.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Cookie';
  font-style: normal;
  font-weight: 400;
  src: local('Cookie-Regular'), url('#{$font-path}/cookie-v10-latin-regular.woff2') format('woff2'), url('#{$font-path}/cookie-v10-latin-regular.woff') format('woff');
  font-display: swap; }
