
html {
  --color-mode: "light";
  --light: #fff;
  --dark:  rgb(28,28,30);
  --haze: #f2f5f7;
  --bubble: rgb(36,36,38);
  --accent: var(--haze);
  --bg: var(--light);
  --code-bg: var(--accent);
  --overlay: var(--light);
  //--text: #111
  --text: #141010;
  --font: 'Barlow', 'Trebuchet MS', 'Tahoma', sans-serif;
  --border-color: #eee;
  --theme: #46819E /* couleur principale - bleu du logo */;
  --theme-secondaire: #E6394B /* couleur secondaire - rouge du logo */;
  --theme-tertiaire: #33A74A /* complémentaire aux deux autres */;
  --theme-focus: #FFD700 /* mise en avant */;
  --inline-color: #33A74A /* idem theme-tertiaire */;
  --ease: ease;
  --scroll-thumb: rgba(0,0,0,.06);
  --search-border-color: transparent;
  --next-icon-path: url(../icons/double-arrow.svg);
  --never-icon-path: url(../sitting.svg);
  --vide-icon: url('../icons/vide.svg');

  @mixin darkmode {
    --color-mode: "dark";
    --theme: #438daf;
    --bg: var(--dark);
    --text: #eee;
    --text-light: #fff;
    --accent: var(--bubble);
    --overlay: var(--bubble);
    --border-color: transparent;
    --scroll-thumb: rgba(255,255,255,.06);
    --search-bg: var(--accent);
    --search-border-color: var(--accent);
    * {
      box-shadow: none !important; } }

  &[data-mode="dark"] {
    @include darkmode;
    .color {
      &_choice {
        &::after {
          background-image: var(--moon-icon); } } } }

  &[data-mode="auto"] {
    @media (prefers-color-scheme: dark) {
      @include darkmode; } } }

%narrow {
  max-width: 750px;
  margin: 0 auto; }

blockquote {
  + .highlight_wrap {
    margin-top: 2.25rem; } }
